import React from "react";

import icon from "../assets/img/favicon-32x32.png";

const main = (
  <>
    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">
          PRIVACY POLICY
        </h1>
      </div>
    </section>

    <section className="avica-main">
      <div className="container impressum">
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            IN4COM GmbH, an Austrian limited liability company with its
            principal address at Schönbrunner Schloßstr. 5/3/6, 1120 Vienna,
            Austria (“IN4COM”) respects the privacy of its users and recognizes
            the importance of providing a secure envi-ronment for them. We are
            observing all corresponding data protection regulations, including
            but not limited to the provisions of the EU General Data Protection
            Regula-tion (hereinafter: "GDPR").
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            This Privacy Policy sets out how we as the Data Controller collect,
            store, use and pro-tect any information when you use our application
            or otherwise engage with us, in-cluding when you visit our websites
            https://www.in4comgroup.com/ and https://avica.cloud (“Websites”).
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            If you have questions regarding this Online Privacy Policy document,
            you may contact IN4COM GmbH at in4@in4comgroup.com.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We use your data to provide and improve the Service and are
            committed to ensuring that your privacy is protected. Should we ask
            you to provide certain information by which you can be identified
            when using our websites, you can be assured that it will only be
            used in accordance with this policy.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            How we use your data:
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            a. User Registration
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We collect several different types of information for various
            purposes to provide and improve our Service to you. We use the
            following data for the user registration, managing the user account,
            providing and maintaining our Service, notifying you about changes
            to our Service, allowing you to participate in interactive features
            of our Service when you choose to do so and to provide customer
            support name, ad-dress, email address, zip/postal code, city, state.
            Without this data, we cannot fulfil the contract with you.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We use the data of registered users for the fulfilment of
            (pre-)contractual obligations pursuant to Art 6 para 1 lit b GDPR
            within the framework of the contractual relation-ship, such as the
            performance of (pre-)contractual duties of protection, due diligence
            and clarification, the provision of services as well as the
            settlement of claims arising from the contract or invoicing.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            In addition, we use the data of registered users for the fulfilment
            of legal or internal compliance obligations in accordance with Art 6
            para 1 lit c and Art 6 para 1 lit f GDPR.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We store the aforementioned data in any case for the duration of
            your active user account and beyond that in accordance with the
            statutory retention and documenta-tion obligations (e.g. in
            accordance with the Austrian Civil Code - ABGB, the Austrian Company
            Code - UGB and the Austrian Federal Tax Codethe - BAO).
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            In the course of processing, the necessary data is passed on to our
            IT service provid-er Hetzner Online GmbH based in Gunzenhausen,
            Germany.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            In addition, your data may be passed on to legal representatives,
            notaries, courts and administrative authorities in case of need.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            b. Usage of the Website for registered Users
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We may also collect information on how the Service is accessed and
            used (“Usage Data”) in order to gather analysis or valuable
            information so that we can improve our Service, to monitor the usage
            of our Service and to detect, prevent and address technical issues.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The data may include information such as your computer’s Internet
            Protocol address (e.g. IP address), computer type, browser type,
            browser version, the pages of our Service that you visit, the time
            and date of your visit, the time spent on those pages, unique device
            identifiers and other diagnostic data.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            c. Tracking Cookies Data
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. We collect the data automatically with
            cookies provided by third-party providers on the basis of your
            consent pursuant to Art 6 para 1 lit a GDPR (or in conjunction with
            Sec 96 para 3 Austrian Telecommunications Act - TKG in the case of
            so-called "persistent" cookies, which are stored on your terminal
            device). Other tracking technologies are also used such as beacons,
            tags and scripts to collect and track information and to improve and
            analyze our Service.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            You can reject the storage of individual cookies (by means of
            configuration in the cookie banner) or object to the storage at a
            later point in time by means of browser configuration. When cookies
            are set on the basis of your voluntary consent, your da-ta may be
            transferred to recipients in third countries outside the EEA, in
            particular to the USA. The USA has not been certified by the
            European Court of Justice as having an adequate level of data
            protection; in particular, there is a risk that your data may be
            viewed by US authorities for control and monitoring purposes. With
            your consent, you agree that cookies from third-party providers in
            the USA or from other insecure third countries may be used and
            accept a possible lower level of data protection (Art 49 para 1 lit
            a GDPR).
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Cookies that are absolutely necessary for the structure or
            functioning of the website (technically necessary cookies) cannot be
            deactivated. No personal data is processed in the process.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The storage period of the respective cookies can vary and amount to
            a maximum of 6 month is based on the duration of the respective
            browser session.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            d. Google Analytics
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Google Analytics is a web analytics service offered by Google that
            tracks and reports website traffic. Google uses the data collected
            to track and monitor the use of our Service. This data is shared
            with other Google services. Google may use the collect-ed data to
            contextualize and personalize the ads of its own advertising
            network.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            You can opt-out of having made your activity on the Service
            available to Google Analytics by installing the Google Analytics
            opt-out browser add-on. The add-on pre-vents the Google Analytics
            JavaScript (ga.js, analytics.js, and dc.js) from sharing information
            with Google Analytics about visits activity.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            For more information on the privacy practices of Google, please
            visit the Google Pri-vacy & Terms web page:
            https://policies.google.com/privacy?hl=en
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            e. Links to Other Sites
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Our Service may contain links to other sites that are not operated
            by us. If you click a third-party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We have no control over and assume no responsibility for the
            content, privacy poli-cies or practices of any third-party sites or
            services.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            f. Subscription to our newsletter
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            If you subscribe to our newsletter, we process your personal data
            (name and e-mail address) in order to send you newsletters and other
            information that may be of in-terest to you at regular intervals.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The data processing is based on your explicit consent according to
            Art 6 para 1 lit a GDPR in connection with § 107 Abs 2 Austrian
            Telecommunications Act - TKG. You can withdraw your consent at any
            time with effect for the future (by E-mail to in4@in4comgroup.com or
            via the "unsubscribe button" in the respective newsletter E-mail).
            Your personal data will be stored until you valid withdrawal.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Controlling Your Personal Information
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            If you believe that any information, we are holding on you is
            incorrect or incomplete, please write to or email or fill-in contact
            form in your user account us as soon as pos-sible. We will promptly
            correct any information found to be incorrect.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Transfer of Data
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other govern-mental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We will make sure that your personal data is transferred on the
            basis of Standard Contractual Clauses (currently 2010/87/EC and/or
            2004/915/EC), if the above-mentioned recipients of your personal
            data are seated outside the EEA and if the Commission has not
            decided that there is an adequate level of data protection in place
            in the country where the recipient of your personal data is seated
            or otherwise in accordance with Articles 46, 47 or 49 GDPR.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Disclosure of Data
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            IN4COM GROUP may disclose your Personal Data in the good faith
            belief that such action is necessary to:
            <br></br>• To comply with a legal obligation.
            <br></br>• To protect and defend the rights or property of IN4COM
            GROUP.
            <br></br>• To prevent or investigate possible wrongdoing in
            connection with the Ser-vice.
            <br></br>• To protect the personal safety of users of the Service or
            the public.
            <br></br>• To protect against legal liability.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Security of Data
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The security of your data is important to us but remember that no
            method of trans-mission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we can-not guarantee
            its absolute security.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Your Data Protection Rights under the GDPR
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            If you are a resident of the European Economic Area (EEA), you have
            certain data protection rights. IN4COM aims to take reasonable steps
            to allow you to cor-rect, amend, delete or limit the use of your
            Personal Data. If you wish to be informed about what Personal Data
            we hold about you and if you want it to be removed from our systems,
            please contact us.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            In certain circumstances, you have the following data protection
            rights:
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The right to access, update or delete the information we have on
            you. Whenever made possible, you can access, update or request
            deletion of your Per-sonal Data directly within your account
            settings section. If you are unable to perform these actions
            yourself, please contact us to assist you.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The right to object. You have the right to object to our processing
            of your Per-sonal Data.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The right of restriction. You have the right to request that we
            restrict the pro-cessing of your personal information.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The right to data portability. You have the right to be provided
            with a copy of the information we have on you in a structured,
            machine-readable and commonly used format.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The right to withdraw consent. You also have the right to withdraw
            your con-sent at any time where IN4COM relied on your consent to
            process your per-sonal information.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA). In Austria, the Data Pro-tection Authority is
            the Datenschutzbehörde (www.dsb.gv.at).
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Service Providers
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We may employ third party companies and individuals to facilitate
            our Service (“Service Providers”), provide the Service on our
            behalf, perform Service-related services or assist us in analyzing
            how our Service is used. These third parties have access to your
            Personal Data only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Changes to This Privacy Policy
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We will let you know via prominent notice on our Website or Service
            and otherwise required by law, prior to the change becoming
            effective and update the “effective date” at the top of this Privacy
            Policy.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page. Your use of our website and applications
            following any changes to this Privacy Policy or your continued
            provision of personal information to us means that you accept the
            revised Privacy Policy.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            How secure is my personal information?
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The security of your personal information is important to IN4COM.
            IN4COM has taken steps to assure that all information collected will
            remain secure. IN4COM follows generally accepted standards to
            protect the personal infor-mation submitted to IN4COM, both during
            transmission and once it is received.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            In addition, IN4COM will take reasonable steps to assure that third
            parties to whom we transfer any data will provide sufficient
            protection of personal information.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            In case you have a question or complaint
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            If you have any questions or wish to file a complaint, please feel
            free to e-mail IN4COM at in4@in4comgroup.com or send a letter
            addressed to IN4COM GmbH, Attention: Privacy Policy Administrator,
            at Schönbrunner Schloßstr. 5/3/6, 1120 Vi-enna, Austria.
          </p>
        </div>
      </div>
    </section>
  </>
);

const Privacy = () => {
  return <>{main}</>;
};

export default Privacy;
