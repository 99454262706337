import React from "react";
import { Link } from "react-router-dom";

import icon from "../assets/img/favicon-32x32.png";
import deco from "../assets/img/deco_160.png";

const main = (
  <>
    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">
          PLATFORM FEATURES
        </h1>
      </div>
    </section>

    <section className="avica">
      <div className="container avica-features">
        <div className="slider-2-section-1">
          <p className="slider-2-title | fs-secondary-heading text-primary-1 fw-semi-bold">
            STORYTELLING TEMPLATES
          </p>
          <p className="slider-2-text | fs-body-bg text-primary-1 fw-light">
            We provide both predefined and dynamic event and data based
            storytelling templates to automate your video content production
            which is the key element to scale production of the video content
            for the business and specific technical purposes (see use cases
            section of the portal).
            <br />
            Adaptable video formats and resolutions, full- and split-screen,
            picture in picture, scene transtion effects, text and graphics
            overlay, sound overlay, graphics animation and other functional
            capabilites are supported by AVICA platform
          </p>
          <img className="slider-2-image" src={deco} alt="" />
        </div>

        <div className="slider-2-section-2">
          <p className="slider-2-title | fs-secondary-heading text-primary-1 fw-semi-bold">
            VIRTUAL EXPERIENCES INTEGRATION
          </p>
          <p className="slider-2-text | fs-body-bg text-primary-1 fw-light">
            We provide integration plugins for Unity and Unreal game engines to
            manage virtual video cameras inside virtual experiences so that XR
            and Metaverse platforms can easily generate and use video content
            for their sales, marketing and product management purposes
            <br />
            With our events and statistics integration plugins storytelling and
            automated video content generation from virtual experiences becomes
            simple and allows production scaling without excessive costs
          </p>
          <img className="slider-2-image" src={deco} alt="" />
        </div>

        <div className="slider-2-section-1">
          <p className="slider-2-title | fs-secondary-heading text-primary-1 fw-semi-bold">
            BUSINESS DATA INTEGRATION
          </p>
          <p className="slider-2-text | fs-body-bg text-primary-1 fw-light">
            AVICA automates creation of the video content and enhances its
            quality and attractiveness based on transformative and generative AI
            data models. It enables such features and effects as AI lip-sync
            avatars, AI graphical and video content generation, AI sound
            manipulations, AI text manipulations (text-to-speech, automated
            translation) etc. Creative opportunities are endless!
          </p>
          <img className="slider-2-image" src={deco} alt="" />
        </div>

        <div className="slider-2-section-2">
          <p className="slider-2-title | fs-secondary-heading text-primary-1 fw-semi-bold">
            BRAND, PRODUCT AND USER DATA
          </p>
          <p className="slider-2-text | fs-body-bg text-primary-1 fw-light">
            AVICA can generate video content which includes DYNAMIC data
            overlays related to specific users, sessions, events, product data
            etc. Opportunities to make content dynamic and engaging are
            limitless, for example branding graphics and messaging, intro- and
            autro-videos, user and team scores, leaderboards, event agenda,
            participants, contacts, product pricing and structured description,
            web links and so on
          </p>
          <img className="slider-2-image" src={deco} alt="" />
        </div>

        <div className="slider-2-section-1">
          <p className="slider-2-title | fs-secondary-heading text-primary-1 fw-semi-bold">
            POST TO SOCIAL MEDIA
          </p>
          <p className="slider-2-text | fs-body-bg text-primary-1 fw-light">
            AVICA can integrate (via accounts linking) with your own social
            media accounts to post generated video content manually or
            automatically based on predefined schedules or events. We also
            provide templates to adapt your content to preferred formats of
            related social media
          </p>
          <img className="slider-2-image" src={deco} alt="" />
        </div>

        <div className="slider-2-section-2">
          <p className="slider-2-title | fs-secondary-heading text-primary-1 fw-semi-bold">
            INTERACTIVE SHOPABLE CONTENT
          </p>
          <p className="slider-2-text | fs-body-bg text-primary-1 fw-light">
            We can make your video content interactive. One example is
            “shopable” videos which are augmented with dynamic product data and
            related links and buttons to E-commerce pages where the product can
            be directly purchased. Another example would be storytelling
            interactivity with story branching depending on user interaction,
            call-to-actions, quizzes, questionnaires, data inputs etc. Use cases
            are limitless to engage with your audience with AVICA!
          </p>
          <img className="slider-2-image" src={deco} alt="" />
        </div>

        <div className="slider-2-section-1">
          <p className="slider-2-title | fs-secondary-heading text-primary-1 fw-semi-bold">
            SOUND AND TEXT AUGMENTATION
          </p>
          <p className="slider-2-text | fs-body-bg text-primary-1 fw-light">
            You can do more than just simple scipts turned into subtitles. Use
            your manual or AI-generated texts and narratives for automated
            storytelling and text-to-speech multilanguage conversions including
            lip-sync avatars. It’s a limitless opportunity to make your video
            content to stand out from the average.
            <br />
            For some of the content types sound tracks selection and its
            synchronisation with video dynamics is very important. We are
            developing functions to automate that
          </p>
          <img className="slider-2-image" src={deco} alt="" />
        </div>

        <div className="slider-2-section-2">
          <p className="slider-2-title | fs-secondary-heading text-primary-1 fw-semi-bold">
            TRANSFORMATIVE AND GENERATIVE AI
          </p>
          <p className="slider-2-text | fs-body-bg text-primary-1 fw-light">
            Advances of modern AI models have direct impact on video content
            production industry as it integrates all components, such as videos,
            graphics, texts and sound, into the resulting content and all those
            components can be generated (text-to-voice, text-to-graphics or
            text-to-video) or manipulated (translation, transformation effects,
            in-painting etc.). Recent large-scale models provide fascinating
            possibilities for creators and content producers.
            <br />
            AVICA team is to integrate those tools and techniques to automate
            video content production process and make it more attractive and
            angaging
          </p>
          <img className="slider-2-image" src={deco} alt="" />
        </div>

        <div className="container avica-main">
          <p className="slider-2-title | fs-secondary-heading text-primary-3 fw-semi-bold">
            ROADMAP
          </p>
          <p className="avica-roadmap fs-block-title text-primary-1 fw-regular">
            Some of the features mentioned above are already available and we
            invite clients to work with us to build automated video content
            generation pipelines. And some of the features are in our R&D
            pipeline to make AVICA the leader of the data driven video content
            generation platform
          </p>
        </div>

        <div className="roadmap-button">
          <button className="button">
            <Link
              className="fs-button fw-semi-bold text-primary-1"
              to="/contacts">
              CONTACT US
            </Link>
          </button>
        </div>
      </div>
    </section>
  </>
);

const Platform = () => {
  return <>{main}</>;
};

export default Platform;
