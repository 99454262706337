import React from "react";

import icon from "../assets/img/favicon-32x32.png";

const main = (
  <>
    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">LEGAL</h1>
      </div>
    </section>

    <section className="avica-main">
      <div className="container impressum">
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The legal documentation regarding end-user licenses and terms of
            service will be published in this section along with the
            commencement of commercial operations.
          </p>
        </div>
      </div>
    </section>
  </>
);

const Legal = () => {
  return <>{main}</>;
};

export default Legal;
