import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/img/in4com-icon-white-100.png";
import fb from "../assets/img/fb-icon-b.png";
import li from "../assets/img/li-icon-b.png";
import yt from "../assets/img/yt-icon-b.png";

const activeClassName = "header-menu-item";

const Footer = () => {
  return (
    <>
      <footer className="primary-footer | fs-body bg-primary-2">
        <div className="container footer-wrapper">
          <a
            href="https://www.in4comgroup.com"
            title="facebook link"
            target="_blank"
            rel="noreferrer">
            <img className="in4com-top-logo" src={logo} alt="IN4COM logo" />
          </a>

          <div className="footer-company">
            <p>IN4COM GmbH</p>
          </div>

          <div className="header-menu" role="list">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to="/impressum">
              IMPRESSUM
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to="/legal">
              LEGAL
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to="/privacy">
              PRIVACY
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to="/contacts">
              CONTACTS
            </NavLink>
          </div>

          <div className="footer-social">
            <div>
              <a
                href="https://www.facebook.com/avicavideo"
                title="facebook link"
                target="_blank"
                rel="noreferrer">
                <img className="social-icon" src={fb} alt="FB link" />
              </a>
            </div>

            <div>
              <a
                href="https://www.linkedin.com/company/avica-platform/"
                title="linkedin link"
                target="_blank"
                rel="noreferrer">
                <img className="social-icon" src={li} alt="LI link" />
              </a>
            </div>

            <div>
              <a
                href="https://www.youtube.com/channel/UChXi2Y6a9-6VWmOCTXxl6sA/featured"
                title="youtube link"
                target="_blank"
                rel="noreferrer">
                <img className="social-icon" src={yt} alt="YT link" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
