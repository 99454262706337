import React from "react";

import slideIcon1 from "../assets/img/ffg-300.png";
import slideIcon2 from "../assets/img/ffg-300.png";
import slideIcon3 from "../assets/img/news_5.png";
import slideIcon4 from "../assets/img/nv-inception-300.png";
import slideIcon5 from "../assets/img/news_4.png";
import slideIcon6 from "../assets/img/news_3.png";
import slideIcon7 from "../assets/img/news_2.png";
import slideIcon8 from "../assets/img/avica-icon-512x512.png";
import slideIcon9 from "../assets/img/VRISCH_logo.jpg";
import slideIcon10 from "../assets/img/Dorotheum_Logo.jpg";
import slideIcon11 from "../assets/img/HV_Logo.png";
import slideIcon12 from "../assets/img/LG_logo.jpg";

export const slides = [
  {
    id: 12,
    date: "2024-03-01",
    content: (
      <div className="slider container">
        <img src={slideIcon12} alt="Start" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">
            Partneship with Loewenstark
          </h3>
          <p className="padding-block-5">
            Match 01, 2024
            <br />
            <br />
            We partner with{" "}
            <a
              href="https://www.loewenstark.com/"
              className="text-primary-3"
              target="_blank"
              rel="noreferrer">
              Loewenstark Digital Group
            </a>{" "}
            to power up product video content mass production using our AVICA
            platform
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 11,
    date: "2023-11-09",
    content: (
      <div className="slider container">
        <img src={slideIcon11} alt="Start" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">
            We are finalists of Austrian Retail Innovation Competition
          </h3>
          <p className="padding-block-5">
            November 09, 2023
            <br />
            <br />
            IN4COM's AVICA solution for DOROTHEUM JUWELIER was selected as{" "}
            <a
              href="https://www.handelsverband.at/presse/presseaussendungen/retail-innovation-awards-2023/"
              className="text-primary-3"
              target="_blank"
              rel="noreferrer">
              finalist
            </a>{" "}
            in the competition organised by Austrian Handelsverband delivering
            data-driven automated video content production platform
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 10,
    date: "2023-06-01",
    content: (
      <div className="slider container">
        <img src={slideIcon10} alt="Start" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">
            Data-driven videos for DOROTHEUM
          </h3>
          <p className="padding-block-5">
            June 2023
            <br />
            <br />
            We partner with{" "}
            <a
              href="https://www.dorotheum-juwelier.com/"
              className="text-primary-3"
              target="_blank"
              rel="noreferrer">
              DOROTHEUM JUWELIER
            </a>{" "}
            to automatically generate product video content using AVICA's
            data-driven technology to reduce costs and enhance engagement with
            customers
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 9,
    date: "2023-06-01",
    content: (
      <div className="slider container">
        <img src={slideIcon9} alt="Start" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">
            Partnership with VRISCH
          </h3>
          <p className="padding-block-5">
            June 2023
            <br />
            <br />
            We partner with Austrian company{" "}
            <a
              href="https://www.vrisch.com"
              className="text-primary-3"
              target="_blank"
              rel="noreferrer">
              VRISCH
            </a>{" "}
            to develop AVICA plugins for Unity and Unreal game engines and
            integrate with the XR content developed by VRISCH
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 8,
    date: "2023-03-01",
    content: (
      <div className="slider container">
        <img src={slideIcon8} alt="Start" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">Our team grows!</h3>
          <p className="padding-block-5">
            March 2023
            <br />
            <br />
            We have 4 new colleagues joining our team to work on our platform:
            front-end developers, back-end developers, video processing, game
            engine plugins and AI tools
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 7,
    date: "2022-11-01",
    content: (
      <div className="slider container">
        <img src={slideIcon1} alt="FFG 2" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">R&D grant from FFG</h3>
          <p className="padding-block-5">
            November 2022
            <br />
            <br />
            AVICA team receives grant from &nbsp;
            <a
              href="https://ffg.at"
              className="text-primary-3"
              target="_blank"
              rel="noreferrer">
              FFG
            </a>
            &nbsp; (Austrian Research Promotion Agency) to support development
            of AI tech for our data-driven content generation engine
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 6,
    date: "2022-04-02",
    content: (
      <div className="slider container">
        <img src={slideIcon2} alt="FFG 1" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">Partnership with FFG</h3>
          <p className="padding-block-5">
            April 2022
            <br />
            <br />
            AVICA team partners with &nbsp;
            <a
              href="https://ffg.at"
              className="text-primary-3"
              target="_blank"
              rel="noreferrer">
              FFG
            </a>
            &nbsp; (Austrian Research Promotion Agency) and Know-Center Graz to
            work on virtual storytelling concept research
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 5,
    date: "2022-04-01",
    content: (
      <div className="slider container">
        <img src={slideIcon3} alt="Tennis Esports" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">
            AVICA partners with Tennis Esports
          </h3>
          <p className="padding-block-5">
            April 2022
            <br />
            <br />
            AVICA partners with &nbsp;
            <a
              href="https://www.tennis-esports.com"
              className="text-primary-3"
              target="_blank"
              rel="noreferrer">
              TENNNIS ESPORTS
            </a>
            &nbsp; team to build automated video content generation engine for
            Tennis Esports League
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 4,
    date: "2022-02-01",
    content: (
      <div className="slider container">
        <img src={slideIcon4} alt="NVidia" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">
            AVICA joins NVIDIA INCEPTION program
          </h3>
          <p className="padding-block-5">
            February 2022
            <br />
            <br />
            AVICA project became a part of &nbsp;
            <a
              href="https://nvda.ws/2BvtUc9"
              className="text-primary-3"
              target="_blank"
              rel="noreferrer">
              NVIDIA Inception
            </a>
            &nbsp; program, designed to nurture startups revolutionizing
            industries with technology advancements
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 3,
    date: "2022-01-01",
    content: (
      <div className="slider container">
        <img src={slideIcon5} alt="Know Center" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">
            Partnership with KNOW CENTER
          </h3>
          <p className="padding-block-5">
            January 2022
            <br />
            <br />
            We partner with researchers from &nbsp;
            <a
              href="https://nvda.ws/2BvtUc9"
              className="text-primary-3"
              target="_blank"
              rel="noreferrer">
              KNOW CENTER Graz
            </a>
            &nbsp; to utilise their knowledge in storytelling, automated video
            directing and video processing
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 2,
    date: "2021-11-01",
    content: (
      <div className="slider container">
        <img src={slideIcon6} alt="MVP" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">
            AVICA MVP version is developed
          </h3>
          <p className="padding-block-5">
            November 2021
            <br />
            <br />
            First version of the AVICA platform is ready for first customers
            supporting automated video highlights generation based on our
            storytelling concept
          </p>
        </div>
      </div>
    ),
  },
  {
    id: 1,
    date: "2021-01-01",
    content: (
      <div className="slider container">
        <img src={slideIcon7} alt="Idea" />
        <div className="slider-content">
          <h3 className="fs-block-title fw-semi-bold">AVICA idea is born</h3>
          <p className="padding-block-5">
            January 2021
            <br />
            <br />
            Team of entrepreneurs and subject-matter experts came up with the
            idea how to automate video content generation using data-driven
            storytelling concept
          </p>
        </div>
      </div>
    ),
  },
];
