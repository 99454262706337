import React from "react";
import { Link } from "react-router-dom";

import Slider from "../components/Slider";

import icon from "../assets/img/favicon-32x32.png";
import ffgIcon from "../assets/img/ffg_logo.png";
import kcIcon from "../assets/img/logo-know-center.svg";
import nvIcon from "../assets/img/logo-nvidia.jpg";
import mvraIcon from "../assets/img/mvr.png";
import tesIcon from "../assets/img/logo-esport.png";
import fenIcon from "../assets/img/logo-fennec-labs.png";
import vrischIcon from "../assets/img/VRISCH_logo.jpg";
import dorotheumIcon from "../assets/img/Dorotheum_Logo.jpg";
import handelsV from "../assets/img/HandelsV.jpg";
import bf from "../assets/img/boris_200.jpg";
import rk from "../assets/img/Rudy.png";
import li from "../assets/img/li-icon-b.png";
import cs from "../assets/img/Can.png";
import nl from "../assets/img/Nikita.png";
import jerry from "../assets/img/Jerry.png";
import af from "../assets/img/Alex.jpeg";
import Loewenstark from "../assets/img/LG_logo.jpg";
// import ur from "../assets/img/user.png";

const inlineStyle1 = {
  backgroundColor: "white",
  border: "5px solid white",
};

const main = (
  <>
    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">
          NEWS & EVENTS
        </h1>
      </div>
    </section>

    <Slider />

    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">
          CLIENTS & PARTNERS
        </h1>
      </div>
    </section>

    <section className="avica-partners">
      <div className="container partner-logos">
        <div className="partner-logo-item">
          <img style={inlineStyle1} src={ffgIcon} alt="FFG" />
        </div>
        <div className="partner-logo-item">
          <img src={kcIcon} alt="Know Center" />
        </div>
        <div className="partner-logo-item">
          <img src={nvIcon} alt="NVidia" />
        </div>
      </div>
    </section>

    <section className="avica-partners">
      <div className="container partner-logos">
        <div className="partner-logo-item">
          <img src={mvraIcon} alt="MY.VR Arena" />
        </div>
        <div className="partner-logo-item">
          <img src={tesIcon} alt="Tennis Esports" />
        </div>
        <div className="partner-logo-item">
          <img src={fenIcon} alt="Fennec" />
        </div>
      </div>
    </section>

    <section className="avica-partners">
      <div className="container partner-logos">
        <div className="partner-logo-item">
          <img src={vrischIcon} alt="Dorotheum" />
        </div>
        <div className="partner-logo-item">
          <img src={dorotheumIcon} alt="VRISCH" />
        </div>
        <div className="partner-logo-item">
          <img src={handelsV} alt="VRISCH" />
        </div>
      </div>
    </section>

    <section className="avica-partners">
      <div className="container partner-logos">
        <div className="partner-logo-item">
          <img src={Loewenstark} alt="Loewenstark" />
        </div>
      </div>
    </section>

    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">TEAM</h1>
      </div>
    </section>

    <section className="avica-management">
      <div className="container management">
        <div className="management-position-1">
          <img className="avatar-image" src={bf} alt="" />
          <p className="avatar-text | fs-block-title text-primary-1 fw-semi-bold">
            BORIS
          </p>
          <p className="avatar-text | fs-body-bg text-primary-1 fw-regular">
            Founder, CEO, CTO
          </p>
          <div>
            <a
              href="https://www.linkedin.com/in/bfedotov/"
              title="linkedin link"
              target="_blank"
              rel="noreferrer">
              <img className="social-icon" src={li} alt="LI link" />
            </a>
          </div>
        </div>
        <div className="management-position-2">
          <img className="avatar-image" src={rk} alt="" />
          <p className="avatar-text | fs-block-title text-primary-1 fw-semi-bold">
            RUDY
          </p>
          <p className="avatar-text | fs-body-bg text-primary-1 fw-regular">
            Founder, CFO
          </p>
        </div>
      </div>

      <div className="container management">
        <div className="management-position-1">
          <img className="avatar-image" src={cs} alt="" />
          <p className="avatar-text | fs-block-title text-primary-1 fw-semi-bold">
            CAN
          </p>
          <p className="avatar-text | fs-body-bg text-primary-1 fw-regular">
            Software developer
          </p>
        </div>
        <div className="management-position-2">
          <img className="avatar-image" src={nl} alt="" />
          <p className="avatar-text | fs-block-title text-primary-1 fw-semi-bold">
            NIKITA
          </p>
          <p className="avatar-text | fs-body-bg text-primary-1 fw-regular">
            Software developer
          </p>
        </div>
      </div>

      <div className="container management">
        <div className="management-position-1">
          <img className="avatar-image" src={af} alt="" />
          <p className="avatar-text | fs-block-title text-primary-1 fw-semi-bold">
            SASHA
          </p>
          <p className="avatar-text | fs-body-bg text-primary-1 fw-regular">
            Content designer
          </p>
        </div>
        <div className="management-position-2">
          <img className="avatar-image" src={jerry} alt="" />
          <p className="avatar-text | fs-block-title text-primary-1 fw-semi-bold">
            JERRY
          </p>
          <p className="avatar-text | fs-body-bg text-primary-1 fw-regular">
            Chief Happiness Officer
          </p>
        </div>
      </div>

      <div className="container management">
        <div className="management-button">
          <button className="button">
            <Link
              className="fs-button fw-semi-bold text-primary-1"
              to="/contacts">
              CONTACT US
            </Link>
          </button>
        </div>
      </div>
    </section>
  </>
);

const Company = () => {
  return <>{main}</>;
};

export default Company;
