import React from "react";
import { Link } from "react-router-dom";

import icon from "../assets/img/favicon-32x32.png";

const main = (
  <>
    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">
          WHAT IS AVICA?
        </h1>
      </div>
    </section>
    <section className="avica">
      <div className="container avica-main">
        <p className="avica-description-top fs-block-title text-primary-1 fw-regular">
          The concept of AVICA platform is based on 3 major ideas which enable
          automated video content production and help to enhance its
          attractiveness and quality
        </p>
      </div>

      <div className="container">
        <div className="slider-section-1">
          <p className="slider-title | fs-primary-heading text-primary-3 fw-bold">
            DATA-DRIVEN
          </p>
          <p className="slider-text | fs-block-title text-primary-1 fw-light">
            AVICA is data-driven automated video content production platform.
            Events, scripts, user and product data are enablers for automated
            storytelling and are sources for high-quality and engaging video
            content for E-commerce and Product Marketing. <br /> <br />
            AVICA allows dynamic video personalization based on user, product or
            event context
          </p>
        </div>

        <div className="slider-section-2">
          <p className="slider-title | fs-primary-heading text-primary-3 fw-bold">
            INTEGRATION
          </p>
          <p className="slider-text | fs-block-title text-primary-1 fw-light">
            AVICA integrates with web portals and business applications such as
            social media platforms, customer data sources, E-commerce
            applications, customer care applications, knowledge management and
            E-learning systems, product catalogs, etc. <br /> <br />
            These integrations enable enable dynamic data-driven storytelling
            and video production scaling without extra costs
          </p>
        </div>

        <div className="slider-section-1">
          <p className="slider-title | fs-primary-heading text-primary-3 fw-bold">
            AI TOOLS
          </p>
          <p className="slider-text | fs-block-title text-primary-1 fw-light">
            AVICA automates creation of the video content and enhances its
            quality and attractiveness based on transformative and generative
            AI/ML data models. It enables such features and effects as AI
            lip-sync avatars, AI graphical and video content generation, AI
            sound manipulations, AI text manipulations (text-to-speech,
            automated translation) etc. <br /> <br /> Creative opportunities are
            endless!
          </p>
        </div>
      </div>
    </section>
    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">
          VIDEO EXAMPLES
        </h1>
      </div>
    </section>

    <section className="avica-video">
      <div className="container video">
        <div className="slider-video">
          <iframe
            width="380"
            height="210"
            src="https://youtube.com/embed/KoHpKcaH2Qo"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
          <iframe
            width="380"
            height="210"
            src="https://youtube.com/embed/nVRn_srf43s"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
        </div>
        <div className="slider-video">
          <iframe
            width="380"
            height="210"
            src="https://youtube.com/embed/Ob5bxOR0Ys0"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
          <iframe
            width="380"
            height="210"
            src="https://youtube.com/embed/7SQKH4OPtB4"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
        </div>
        <div className="slider-video">
          <iframe
            width="380"
            height="210"
            src="https://youtube.com/embed/b2t7rC9LwCw"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
          <iframe
            width="380"
            height="210"
            src="https://youtube.com/embed/pwlxVPvGAXQ"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
        </div>
        <div className="slider-video">
          <iframe
            width="380"
            height="210"
            src="https://www.youtube.com/embed/AyLSIW7pvqo"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
          <iframe
            width="380"
            height="210"
            src="https://www.youtube.com/embed/2jWJOcRf7R8"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
        </div>
        <div className="slider-video">
          <iframe
            width="380"
            height="210"
            src="https://www.youtube.com/embed/fl83Mb-Odpg"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
          <iframe
            width="380"
            height="210"
            src="https://www.youtube.com/embed/PhTAa_JquHA"
            title="YouTube video player"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen></iframe>
        </div>

        <div className="video-button">
          <button className="button">
            <Link
              className="fs-button fw-semi-bold text-primary-1"
              to="/contacts">
              CONTACT US
            </Link>
          </button>
        </div>
      </div>
    </section>
  </>
);

const Home = () => {
  return <>{main}</>;
};

export default Home;
