import React from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./layout/Layout";
import Home from "./pages/Home";
import Platform from "./pages/Platform";
import UseCases from "./pages/UseCases";
import Company from "./pages/Company";
import Impressum from "./pages/Impressum";
import Legal from "./pages/Legal";
import Privacy from "./pages/Privacy";
import Contacts from "./pages/Contacts";
import P404 from "./pages/P404";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/usecases" element={<UseCases />} />
        <Route path="/company" element={<Company />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="*" element={<P404 />} />
      </Routes>
    </Layout>
  );
}

export default App;
