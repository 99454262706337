import React from "react";
import { Link } from "react-router-dom";

import icon from "../assets/img/favicon-32x32.png";

const main = (
  <>
    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">USECASES</h1>
      </div>
    </section>

    <section className="avica-main">
      <div className="container avica-usecases">
        <div>
          <p className="slider-3-title fs-secondary-heading text-primary-1 fw-semi-bold">
            MAKE VIDEOS WITH AVICA FOR YOUR:
          </p>
        </div>

        <div className="stripe-1">
          <div className="menu-icon">
            <img className="media-title-icon" src={icon} alt="" />
            <p className="slider-2-title fs-secondary-heading text-primary-1 fw-semi-bold">
              PRODUCTS & SERVICES
            </p>
          </div>
          <p className="slider-text | fs-block-title text-primary-1 fw-light paragraph">
            Systems & channels:
          </p>
          <div className="paragraph">
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Product catalogues
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - E-commerce portals
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - E-shops
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Websites / CMS
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Customer care systems
            </p>
          </div>
          <p className="slider-text | fs-block-title text-primary-1 fw-light paragraph">
            Use cases examples:
          </p>
          <div className="paragraph">
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Consumer goods
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Real estate
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Cars
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Services offering (e.g. telco tariffs)
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Games / Esports
            </p>
          </div>
        </div>
        <div className="stripe-1">
          <div className="menu-icon">
            <img className="media-title-icon" src={icon} alt="" />
            <p className="slider-2-title fs-secondary-heading text-primary-1 fw-semi-bold">
              USERS & CLIENTS
            </p>
          </div>
          <p className="slider-text | fs-block-title text-primary-1 fw-light paragraph">
            Systems & channels:
          </p>
          <div className="paragraph">
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - News websites
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Corporate news
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Social media news channels
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Bloggers & influencers
            </p>
          </div>
          <p className="slider-text | fs-block-title text-primary-1 fw-light paragraph">
            Use cases examples:
          </p>
          <div className="paragraph">
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Financial news, stock market
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Sport news
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Scores, leaderboards, teams
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Domain specific news
            </p>
          </div>
        </div>
        <div className="stripe-1">
          <div className="menu-icon">
            <img className="media-title-icon" src={icon} alt="" />
            <p className="slider-2-title fs-secondary-heading text-primary-1 fw-semi-bold">
              NEWS & POSTS
            </p>
          </div>
          <p className="slider-text | fs-block-title text-primary-1 fw-light paragraph">
            Systems & channels:
          </p>
          <div className="paragraph">
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - User accounts
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Customer care systems
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Website users
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - MarTech / AdTech user profiles
            </p>
          </div>
          <p className="slider-text | fs-block-title text-primary-1 fw-light paragraph">
            Use cases examples:
          </p>
          <div className="paragraph">
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - User statistics & trends
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Personalized offers
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Game sessions summary
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Summary of items viewed on web portal
            </p>
            <p className="slider-text | fs-block-title text-primary-1 fw-light">
              - Shoppable videos
            </p>
          </div>
        </div>

        <div className="roadmap-button">
          <button className="button">
            <Link
              className="fs-button fw-semi-bold text-primary-1"
              to="/contacts">
              CONTACT US
            </Link>
          </button>
        </div>
      </div>
    </section>
  </>
);

const UseCases = () => {
  return <>{main}</>;
};

export default UseCases;
