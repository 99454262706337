import React from "react";

import icon from "../assets/img/favicon-32x32.png";

const P404 = () => {
  return (
    <>
      <section className="section-title">
        <div className="container media-title">
          <img className="media-title-icon" src={icon} alt="" />
          <h1 className="fs-primary-heading text-primary-1 fw-bold">
            ERROR PAGE
          </h1>
          <div className="paragraph padding-block-3">
            <p className="fs-body-bg text-primary-1 fw-regular">
              Oops... something went wrong! Please go to Home page
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default P404;
