import React from "react";

import icon from "../assets/img/favicon-32x32.png";

const main = (
  <>
    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">IMPRESSUM</h1>
      </div>
    </section>

    <section className="avica-main">
      <div className="container impressum">
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Firmawortlaut:
            <br></br>
            IN4COM GmbH
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            UID-Nummer:
            <br></br>
            ATU 75168328
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Firmenbuchnummer:
            <br></br>
            FN 527514 g
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Firmenbuchgericht:
            <br></br>
            Handelsgericht Wien
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Firmensitz:
            <br></br>
            Schönbrunner Schloßstraße 5/3/6, 1120, Wien, Österreich
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Kontaktdaten (Tel, E-Mail):
            <br></br>
            Tel: +43 664 8322118, E-Mail: in4@in4comgroup.com
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Mitgliedschaften bei der Wirtschaftskammerorganisation:
            <br></br>
            Wirtschaftskammer Wien #0533625
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Anwendbare Rechtsvorschriften:
            <br></br>
            Anwendbare Rechtsvorschriften sind insbesondere die Gewerbeordnung
            in der jeweils gültigen Fassung, abrufbar unter
            http://www.ris.bka.gv.at
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Aufsichtsbehörde / Gewerbebehörde:
            <br></br>
            Magistratisches Bezirksamt 15 (MBA 15), Wien
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Angaben zur Online-Streitbeilegung:
            <br></br>
            Verbraucher haben die Möglichkeit, Beschwerden an die Online-
            Streitbeilegungsplattform der EU zu richten:
            http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch an
            die oben angegebene E-Mail-Adresse richten
          </p>
        </div>
      </div>
    </section>
  </>
);

const Impressum = () => {
  return <>{main}</>;
};

export default Impressum;
