import React from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../assets/img/avica-icon-100x100.png";

const activeClassName = "header-menu-item";

const svg = (
  <svg
    className="header-login-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
    />
  </svg>
);

const Header = () => {
  return (
    <>
      <header className="primary-header fs-nav bg-primary-2">
        <div className="container primary-header-wrapper">
          <Link to="/home">
            <img className="avica-top-logo" src={logo} alt="AVICA logo" />
          </Link>

          <div className="header-menu" role="list">
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to="/home">
              HOME
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to="/platform">
              PLATFORM
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to="/usecases">
              USE CASES
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? activeClassName : undefined
              }
              to="/company">
              COMPANY
            </NavLink>
          </div>

          <div className="header-login">
            <a
              href="https://portal.avica.cloud/my"
              title="facebook link"
              target="_blank"
              rel="noreferrer">
              {svg}
            </a>
          </div>
        </div>
      </header>

      <section className="secondary-header | bg-primary-2">
        <div className="container secondary-header-wrapper">
          <h1 className="text-primary-3 fw-semi-bold fs-80">AVICA</h1>
          <h2 className="fs-primary-heading text-primary-1 fw-semi-bold">
            DATA-DRIVEN VIDEO CONTENT
            <br />
            CREATION PLATFORM
          </h2>
          <button className="button">
            <Link
              className="fs-button fw-semi-bold text-primary-1"
              to="/contacts">
              CONTACT US
            </Link>
          </button>
        </div>
      </section>
    </>
  );
};

export default Header;
