import React from "react";

import icon from "../assets/img/favicon-32x32.png";
import fb from "../assets/img/fb-icon-b.png";
import li from "../assets/img/li-icon-b.png";
import yt from "../assets/img/yt-icon-b.png";

import position1 from "../assets/pdf/fsdev.pdf";
import position2 from "../assets/pdf/mleng.pdf";
import position3 from "../assets/pdf/gamedev.pdf";

const inlineStyle1 = {
  textDecoration: "none",
};

const inlineStyle2 = {
  border: 0,
};

const main = (
  <>
    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">COMPANY</h1>
      </div>
    </section>

    <section className="avica-main">
      <div className="container impressum">
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            The AVICA platform (avica.cloud) is developed and supported by the
            company IN4COM GmbH (www.in4comgroup.com).
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            IN4COM GmbH provides services and delivers solutions for such
            technology and business domains as:
            <br />
            XR technologies development and integration
            <br />
            XR / immersive experiences content development
            <br />
            Real-time motion tracking and motion analysis
            <br />
            LBE management platforms development and deployment
            <br />
            Esports and tournaments management platforms
            <br />
            Player matchmaking engagement tech via social media tools and
            platforms
            <br />
            Digital Product Information Management platform delivery
            <br />
            Intellectual Operational Effectiveness platform delivery
            <br />
            Marketing Technologies Automation platform delivery
            <br />
            Custom software solution development.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Our office address:
            <br />
            Schönbrunner Schloßstraße 5/3/6, 1120, Vienna, Austria
          </p>
        </div>
        <div className="paragraph padding-block-7">
          <p className="fs-body-bg text-primary-1 fw-regular">
            We are hiring! Currently open positions are:
          </p>
          <p className="fs-body-bg text-primary-1 fw-regular">
            <a href={position1} style={inlineStyle1} className="text-primary-3">
              Full-stack software developer
            </a>
          </p>
          <p className="fs-body-bg text-primary-1 fw-regular">
            <a href={position2} style={inlineStyle1} className="text-primary-3">
              ML engineer
            </a>
          </p>
          <p className="fs-body-bg text-primary-1 fw-regular">
            <a href={position3} style={inlineStyle1} className="text-primary-3">
              Game engine integration developer
            </a>
          </p>
        </div>
      </div>
    </section>

    <section className="address">
      <div className="container">
        <iframe
          className="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2660.2184577257694!2d16.323382116615182!3d48.183142255963695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476da811176aa8b1%3A0xed9e3ade74bc0fe1!2sSch%C3%B6nbrunner%20Schlo%C3%9Fstra%C3%9Fe%205%2C%201120%20Wien!5e0!3m2!1sen!2sat!4v1665912468242!5m2!1sen!2sat"
          style={inlineStyle2}
          allowFullScreen=""
          loading="lazy"
          title="G-map"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </section>

    <section className="section-title">
      <div className="container media-title">
        <img className="media-title-icon" src={icon} alt="" />
        <h1 className="fs-primary-heading text-primary-1 fw-bold">CONTACTS</h1>
      </div>
    </section>

    <section className="avica-main">
      <div className="container | fs-body fw-regular text-secondary-1">
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Tell us about your tasks and routines related top video content
            generation and we can work together making the process fully
            automated and delivering quality results to improve your marketing,
            sales and operational KPIs.
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            Our office address:
            <br />
            Schönbrunner Schloßstraße 5/3/6, 1120, Vienna, Austria
          </p>
          <p className="fs-body-bg text-primary-1 fw-regular">
            Contact email: in4 @ in4comgroup . com
          </p>
        </div>
        <div className="paragraph padding-block-3">
          <p className="fs-body-bg text-primary-1 fw-regular">
            You can get in touch with us via social media channels:
          </p>
        </div>
        <div>
          <div className="contact-social">
            <div>
              <a
                href="https://www.facebook.com/avicavideo"
                title="facebook link"
                target="_blank"
                rel="noreferrer">
                <img className="social-icon" src={fb} alt="FB link" />
              </a>
            </div>

            <div>
              <a
                href="https://www.linkedin.com/company/avica-platform/"
                title="linkedin link"
                target="_blank"
                rel="noreferrer">
                <img className="social-icon" src={li} alt="LI link" />
              </a>
            </div>

            <div>
              <a
                href="https://www.youtube.com/channel/UChXi2Y6a9-6VWmOCTXxl6sA/featured"
                title="youtube link"
                target="_blank"
                rel="noreferrer">
                <img className="social-icon" src={yt} alt="YT link" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

const Contacts = () => {
  return <>{main}</>;
};

export default Contacts;
