import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";

import { slides } from "../components/Slides";

const Slider = () => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}>
        <ul>
          {slides.map((element) => (
            <li key={element.date}>
              <SwiperSlide key={element.date}>{element.content}</SwiperSlide>
            </li>
          ))}
        </ul>
      </Swiper>
    </>
  );
};

export default Slider;
